import React from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom';
import About from './about'
import Watch from './watch'


class App extends React.Component{
	state = {}
	componentDidMount(){
	}

  render(){
	  return (
	    <div className="App">
	    <Router>
	    <Route path = '/about' component = {About}/>
	    <Route exact path = '/' component = {Watch}/>
	     </Router>

	    </div>

	  );
	}
}

export default App;
