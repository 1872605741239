import React from 'react';
import {Navbar, Nav} from 'react-bootstrap'
import './App.css';

function About(){
	return(
		<div>
	    <Navbar bg="light" expand="lg">
	    	<Navbar.Brand href="/">Beating The Boxes</Navbar.Brand>
	    	<Navbar.Collapse className="justify-content-end">
			      <Nav.Link href="/">Home</Nav.Link>
			  </Navbar.Collapse>
	    </Navbar>	
	    	<h2>Hello!</h2>
	</div>	
	)
}

export default About;