import React from 'react'
import { Button} from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ReactPlayer from 'react-player'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css';
import './watch.css';
import axios from 'axios';
import ls from 'local-storage'
import ListGroup from 'react-bootstrap/ListGroup'
import './accordion.css';
import {Navbar, Nav} from 'react-bootstrap'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { BrowserRouter as Router, Route} from 'react-router-dom';
import Popup from "reactjs-popup";
import About from './about'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

let basic_url = "https://raw.githubusercontent.com/zhuf11/beating-the-boxes/master/basic.json"
let beginner_url = "https://raw.githubusercontent.com/zhuf11/beating-the-boxes/master/beginner.json"
let inter_url = "https://raw.githubusercontent.com/zhuf11/beating-the-boxes/master/inter.json"
let adv_url = "https://raw.githubusercontent.com/zhuf11/beating-the-boxes/master/adv.json"
let current_url = "https://raw.githubusercontent.com/zhuf11/beating-the-boxes/master/basic.json"

let sounds_5_learned = false;
let sounds_10_learned = false;
let sounds_15_learned = false;
let sounds_20_learned = false;

let title_text = ''
let channel_text = ''
let sound_text = ''

let beginner_tab_text = 'Beginner'
let inter_tab_text = 'Intermediate'
let adv_tab_text = 'Advanced'
let saved_videos_text = 'Saved Videos'

let save_button_text = 'Save this Video!'

let learned_sounds_list = []
let learned_sounds_num = 0
let beginner_learned_sounds_num = 0
let inter_learned_sounds_num = 0
let adv_learned_sounds_num = 0
let last_four_videos_seen = []

let saved_videos_list = []
let beginner_list_sounds = ['PF Snare', 'Psh Snare', 'Tongue Click', 'Lip Oscillation', 'Inward K Snare', 'Throat Kick', 'Electro Scratch', 'Zede Scratch']
let inter_list_sounds = ['Trumpet', 'Hollow Clop', 'Sucker Punch', 'Lip Roll', 'Siren', 'Vocal Tap', 'Throat Bass', 'Water Drop', 'Crab Scratch', 'Zipper', 'Synth Sound', 'Chest Bass', "Tongue Bass", "Kick Rolls", 'Trap Hi-Hat', 'Humming and Beatboxing', 'Click Roll', 'OD Bass']
let adv_list_sounds = ['Wob Wob Bass', 'BMG Snare', 'Inward Peh Snare', 'Inward Drag', 'Sega Sound', 'Cricket Sound', 'tkK', 'Colaps Click']
toast.configure()

let bl_sounds = ''
let inter_sounds = ''
let adv_sounds = ''
let saved_videos = ''

function scroll_to_tabs(){
		window.scrollTo({ top: 500, behavior: 'smooth' }) 
	}

class Watch extends React.Component{
	state = {}
	componentDidMount(){
		document.title = 'Beating The Boxes';
		let temp = ls.get("stored_ls");
    	if (temp !== null){
    		temp = JSON.parse(temp)
    		this.setState({ learned_sounds: temp});
    	}


    	let temp_vids = ls.get("stored_saved_vids");
    	if (temp_vids !== null){
    		temp_vids = JSON.parse(temp_vids)
    		this.setState({ saved_videos: temp_vids});
    	}
    	this.setState({ learned_sounds: temp})
    	this.setState({ saved_videos: temp_vids})

    	learned_sounds_list = this.state.learned_sounds
    	saved_videos_list = this.state.saved_videos
    	

    	if (learned_sounds_list == null){
    		learned_sounds_list = []
    	}

    	if (saved_videos_list == null){
    		saved_videos_list = []
    	}

    	learned_sounds_num = learned_sounds_list.length

    	if(learned_sounds_num >= 5){
    		sounds_5_learned = true;
    	}
    	if(learned_sounds_num >= 10){
    		sounds_10_learned = true;
    	}
    	if(learned_sounds_num >= 15){
    		sounds_15_learned = true;
    	}    	
    	if(learned_sounds_num >= 20){
    		sounds_20_learned = true;
    	}

		bl_sounds = beginner_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				return <button type="button" className="list-group-item list-group-item-action" data-toggle="popover" title="Popover title" data-content="And here's some amazing content. It's very engaging. Right?" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" className="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'beginner')}}>{sound_name} </button>
		}}
	)

		inter_sounds = inter_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				//return <ListGroup.Item as="li" style = {{color: 'gray'}}>✔ {sound_name} ✔</ListGroup.Item>
				return <button type="button" className="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" className="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'inter')}}>{sound_name} </button>
		}}
	)

		adv_sounds = adv_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				//return <ListGroup.Item as="li" style = {{color: 'gray'}}>✔ {sound_name} ✔</ListGroup.Item>
				return <button type="button" className="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" className="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'adv')}}>{sound_name} </button>
		}}
	)

		for(var sound of learned_sounds_list){
			if(beginner_list_sounds.includes(sound)){
				beginner_learned_sounds_num += 1
			}


			if(inter_list_sounds.includes(sound)){
				inter_learned_sounds_num += 1
			}

			if(adv_list_sounds.includes(sound)){
				adv_learned_sounds_num += 1
			}
		}
		beginner_tab_text = "Beginner (" + beginner_learned_sounds_num + '/' + beginner_list_sounds.length + ')' 
		inter_tab_text = "Intermediate (" + inter_learned_sounds_num + '/' + inter_list_sounds.length + ')' 
		adv_tab_text = "Advanced (" + adv_learned_sounds_num + '/' + adv_list_sounds.length + ')' 

		console.log(saved_videos_list)
		console.log(saved_videos_list.length)

		saved_videos_text = "Saved Videos (" + saved_videos_list.length + ')'
		saved_videos = saved_videos_list.map((video => {
			console.log(video)
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.PlayUrl(video[0], video[2], video[1], video[3])}}>{video[2]} - {video[1]}</button>
		})
		)

		 
		//ls.clear()

		this.forceUpdate();
	}


	constructor(props) {
		super(props)

		let temp = ls.get("stored_ls");
    	if (temp !== null){
    		temp = JSON.parse(temp)
    		this.setState({ learned_sounds: temp});
    	}


    	let temp_vids = ls.get("stored_saved_vids");
    	if (temp_vids !== null){
    		temp_vids = JSON.parse(temp_vids)
    		this.setState({ saved_videos: temp_vids});
    	}

    	this.setState({
			url: '',
			title: '',
			sameSoundText: "",
			learned_sounds: temp,
			channel: "",
			saved_videos: temp_vids
		})

    	this.state.learned_sounds = temp
		this.state.saved_videos = temp_vids

    	learned_sounds_list = this.state.learned_sounds
    	saved_videos_list = this.state.saved_videos

    	if (learned_sounds_list == null){
    		learned_sounds_list = []
    	}

    	if (saved_videos_list == null){
    		saved_videos_list = []
    	}

		this.SpecificSoundClick = this.SpecificSoundClick.bind(this);
		this.BasicButtonClick = this.BasicButtonClick.bind(this);
		this.BeginnerButtonClick = this.BeginnerButtonClick.bind(this);
		this.InterButtonClick = this.InterButtonClick.bind(this);
		this.AdvButtonClick = this.AdvButtonClick.bind(this);
		this.SameSoundButtonClick = this.SameSoundButtonClick.bind(this);
		this.LearnedSoundButtonClick = this.LearnedSoundButtonClick.bind(this);
		this.RemovedLearnedSoundClick = this.RemovedLearnedSoundClick.bind(this);
		this.SaveVideoButtonClick = this.SaveVideoButtonClick.bind(this)

	}

	SaveVideoButtonClick(){
		if(save_button_text === 'Unsave Video!'){
			saved_videos_list = saved_videos_list.filter(element => element[0] !== this.state.url)
			saved_videos_text = "Saved Videos (" + saved_videos_list.length + ')'
			save_button_text = 'Save this Video!'

			ls.set("stored_saved_vids", JSON.stringify(saved_videos_list))

			this.setState({
				saved_videos: saved_videos_list
			})
		}

		else{
			if(this.state.url === undefined){
				console.log('invalid url')
			}

			else{
				console.log(saved_videos_list)
				saved_videos_list.push([this.state.url, this.state.channel, this.state.title, this.state.sameSoundText])
				console.log(saved_videos_list)
				ls.set("stored_saved_vids", JSON.stringify(saved_videos_list))
			}

			for(let sv of saved_videos_list){
				if(sv[0] === this.state.url){
					save_button_text = 'Unsave Video!'
				}
			}

		}

		saved_videos = saved_videos_list.map((video => {
			console.log(video)
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.PlayUrl(video[0], video[2], video[1], video[3])}}>{video[2]} - {video[1]}</button>
		})
		)
		saved_videos_text = "Saved Videos (" + saved_videos_list.length + ')'
		this.setState({
			saved_videos:saved_videos_list
		})
		
	}

	PlayUrl(url, title, channel, sound){
		for(let sv of saved_videos_list){
			if(sv[0] === url){
				save_button_text = 'Unsave Video!'
				this.forceUpdate();
			}
		}
		last_four_videos_seen.push(url)
      	if(last_four_videos_seen.length >= 5){
      		last_four_videos_seen.shift()
      	}
      	title_text = title
      	channel_text = channel
      	sound_text = sound
      	window.scrollTo({top:0, behavior: 'smooth'})
	    this.setState({
	    	url: url,
	    	title: title,
	    	sameSoundText: sound,
	    	channel: channel
	    })

	}
	SpecificSoundClick(sound_name, difficulty){
		save_button_text = 'Save this Video!'
		if (difficulty === 'beginner'){
			current_url = beginner_url
		}

		if (difficulty === 'inter'){
			current_url = inter_url
		}

		if (difficulty === 'adv'){
			current_url = adv_url
		}

		axios.get(current_url).then(res => {
      	let current_data = res.data

  		current_data = current_data.filter(vid =>
  		{
  			return (vid.sound === sound_name & !last_four_videos_seen.includes(vid.url))
  		})

  	    var keys = Object.keys(current_data);
		var len = keys.length
		learned_sounds_num = learned_sounds_list.length
      	let videoNum = Math.floor(Math.random() * len)
      	let video = current_data[videoNum]

      	last_four_videos_seen.push(video['url'])
      	if(last_four_videos_seen.length >= 5){
      		last_four_videos_seen.shift()
      	}
      	console.log(last_four_videos_seen)

      	title_text = video['title']
      	channel_text = video['channel']
      	sound_text = video['sound']
      	window.scrollTo({top:0, behavior: 'smooth'})
	    this.setState({
	    	url: video['url'],
	    	title: video['title'],
	    	sameSoundText: sound_text,
	    	channel: video['channel']
	  }) //change url

	})}

	BasicButtonClick() {
	  save_button_text = 'Save this Video!'
	  current_url = basic_url
	  console.log(learned_sounds_list)

	  if(learned_sounds_list === undefined || learned_sounds_list === null){
	  	learned_sounds_list = []
	  }
	  
	  axios.get(basic_url).then(res => {
      	let basic_data = res.data

      	for (var lsound of learned_sounds_list){
      		basic_data =  basic_data.filter(vid =>
      		{
      			return !(vid.sound === lsound & !last_four_videos_seen.includes(vid.url))
      		})
      	}
      	var keys = Object.keys(basic_data);
		var len = keys.length
		if (len < 1){
			toast.success("🎤 You've learned all the basic sounds!", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				})
		}
		else{
	      	let videoNum = Math.floor(Math.random() * len)
	      	let video = basic_data[videoNum]

	      	last_four_videos_seen.push(video['url'])
	      	if(last_four_videos_seen.length >= 5){
	      		last_four_videos_seen.shift()
	      	}
	      	console.log(last_four_videos_seen)


	      	title_text = video['title']
	      	channel_text = video['channel']
	      	sound_text = video['sound']

		    this.setState({
		    	url: video['url'],
		    	title: video['title'],
		    	sameSoundText: sound_text,
		    	channel: video['channel']
		  }) //change url
	}})
	}

	BeginnerButtonClick = () => {
		console.log(learned_sounds_list )
	  save_button_text = 'Save this Video!'
	  current_url = beginner_url

	  if(learned_sounds_list === undefined || learned_sounds_list === null){
	  	learned_sounds_list = []
	  }

	  axios.get(beginner_url).then(res => {
      	let beginner_data = res.data

        for (var lsound of learned_sounds_list){
      		beginner_data =  beginner_data.filter(vid =>
      		{
      			return !(vid.sound === lsound & !last_four_videos_seen.includes(vid.url))
      		})
      	}
      	var keys = Object.keys(beginner_data);
		var len = keys.length

		if (len < 1){
			alert("You've learned all the beginner-level sounds!")
		}
		else{
      	let videoNum = Math.floor(Math.random() * len)
      	let video = beginner_data[videoNum]

      	last_four_videos_seen.push(video['url'])
      	if(last_four_videos_seen.length >= 5){
      		last_four_videos_seen.shift()
      	}
      	console.log(last_four_videos_seen)

      	for(lsound of learned_sounds_list){
			beginner_data = beginner_data.filter(list => list.sound !== video['sound'])
  	}
      	title_text = video['title']
      	channel_text = video['channel']
      	sound_text = video['sound']

	    this.setState({
	    	url: video['url'],
	    	title: video['title'],
	    	sameSoundText: sound_text,
	    	channel: video['channel']
	  }) //change url
	}})
	}

	InterButtonClick = () => {
	  save_button_text = 'Save this Video!'
	  current_url = inter_url
	  learned_sounds_list = this.state.learned_sounds

	  if(learned_sounds_list === undefined || learned_sounds_list === null){
	  	learned_sounds_list = []
	  }

	  axios.get(inter_url).then(res => {
      	let inter_data = res.data
      	for (var lsound of learned_sounds_list){
      		inter_data =  inter_data.filter(vid =>
      		{
      			return !(vid.sound === lsound & !last_four_videos_seen.includes(vid.url))
      		})
      	}
      	var keys = Object.keys(inter_data);
		var len = keys.length
		if (len < 1){
			alert("You've learned all the intermediate sounds!")
		}
		else{
      	let videoNum = Math.floor(Math.random() * len)
      	let video = inter_data[videoNum]
      	console.log(video)

        last_four_videos_seen.push(video['url'])
      	if(last_four_videos_seen.length >= 5){
      		last_four_videos_seen.shift()
      	}
      	console.log(last_four_videos_seen)

      	title_text = video['title']
      	channel_text = video['channel']
      	sound_text = video['sound']

	    this.setState({
	    	url: video['url'],
	    	title: video['title'],
	    	sameSoundText: sound_text,
	    	channel: video['channel']
	  }) //change url
	}})
	}

	AdvButtonClick = () => {
	  save_button_text = 'Save this Video!'
	  current_url = adv_url
	  learned_sounds_list = this.state.learned_sounds

	  if(learned_sounds_list === undefined || learned_sounds_list === null){
	  	learned_sounds_list = []
	  }

	  axios.get(adv_url).then(res => {
      	let adv_data = res.data
      	for (var lsound of learned_sounds_list){
      		adv_data =  adv_data.filter(vid =>
      		{
      			return !(vid.sound === lsound & !last_four_videos_seen.includes(vid.url))
      		})
      	}
      	var keys = Object.keys(adv_data);
		var len = keys.length
		if (len < 1){
			alert("You've learned all the advanced sounds!")
		}
		else{
      	let videoNum = Math.floor(Math.random() * len)
      	let video = adv_data[videoNum]
      	console.log(video)

        last_four_videos_seen.push(video['url'])
      	if(last_four_videos_seen.length >= 5){
      		last_four_videos_seen.shift()
      	}
      	console.log(last_four_videos_seen)

      	title_text = video['title']
      	channel_text = video['channel']
      	sound_text = video['sound']

	    this.setState({
	    	url: video['url'],
	    	title: video['title'],
	    	sameSoundText: sound_text,
	    	channel: video['channel']
	  }) //change url
	}})
	}

	SameSoundButtonClick = () => {
	  save_button_text = 'Save this Video!'
	  axios.get(current_url).then(res => {
      	let same_data = res.data.filter(list => list.sound.toLowerCase().includes(sound_text.toLowerCase()))
      	same_data = same_data.filter(vid =>
      		{
      			return !(last_four_videos_seen.includes(vid.url))
      		})

      	var keys = Object.keys(same_data);
		var len = keys.length
      	let videoNum = Math.floor(Math.random() * len)
      	let video = same_data[videoNum]
      	console.log(video)
      	last_four_videos_seen.push(video['url'])
      	if(last_four_videos_seen.length >= 5){
      		last_four_videos_seen.shift()
      	}
      	console.log(last_four_videos_seen)

      	title_text = video['title']
      	channel_text = video['channel']
      	sound_text = video['sound']
	    this.setState({
	    	url: video['url'],
	    	title: video['title'],
	    	sameSoundText: sound_text,
	    	channel: video['channel']
	  }) //change url
	})
	}

	RemovedLearnedSoundClick(sound_name){
		learned_sounds_list = learned_sounds_list.filter(x=> x !== sound_name)
		learned_sounds_num = learned_sounds_list.length
		ls.set("stored_ls", JSON.stringify(learned_sounds_list))

		bl_sounds = beginner_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				return <button type="button" class="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'beginner')}}>{sound_name} </button>
		}}
	)

		inter_sounds = inter_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				//return <ListGroup.Item as="li" style = {{color: 'gray'}}>✔ {sound_name} ✔</ListGroup.Item>
				return <button type="button" class="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'inter')}}>{sound_name} </button>
		}}
	)
		adv_sounds = adv_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				//return <ListGroup.Item as="li" style = {{color: 'gray'}}>✔ {sound_name} ✔</ListGroup.Item>
				return <button type="button" class="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'adv')}}>{sound_name} </button>
		}}
	)
		beginner_learned_sounds_num = 0
		inter_learned_sounds_num = 0
		adv_learned_sounds_num = 0
		for(var sound of learned_sounds_list){
			if(beginner_list_sounds.includes(sound)){
				beginner_learned_sounds_num += 1
			}


			if(inter_list_sounds.includes(sound)){
				inter_learned_sounds_num += 1
			}

			if(adv_list_sounds.includes(sound)){
				adv_learned_sounds_num += 1
			}
		}
	beginner_tab_text = "Beginner (" + beginner_learned_sounds_num + '/' + beginner_list_sounds.length + ')' 
	inter_tab_text = "Intermediate (" + inter_learned_sounds_num + '/' + inter_list_sounds.length + ')' 
	adv_tab_text = "Advanced (" + adv_learned_sounds_num + '/' + adv_list_sounds.length + ')' 

	this.setState({
		learned_sounds: learned_sounds_list
	  	}) 
	}

	LearnedSoundButtonClick = () => {
		let saved_videos_text = 'Saved Videos'
		if(learned_sounds_list.includes(sound_text)){
			console.log('Already There')
		}

		else{
			if(sound_text !== ""){
				learned_sounds_list.push(sound_text)
			}
			toast.success("Awesome! You just learned the " + sound_text + '!', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				})
		}


		bl_sounds = beginner_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				return <button type="button" class="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'beginner')}}>{sound_name} </button>
		}}
	)

		inter_sounds = inter_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				//return <ListGroup.Item as="li" style = {{color: 'gray'}}>✔ {sound_name} ✔</ListGroup.Item>
				return <button type="button" class="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'inter')}}>{sound_name} </button>
		}}
	)
		adv_sounds = adv_list_sounds.map((sound_name) => {
			if(learned_sounds_list.includes(sound_name)){
				//return <ListGroup.Item as="li" style = {{color: 'gray'}}>✔ {sound_name} ✔</ListGroup.Item>
				return <button type="button" class="list-group-item list-group-item-action" style = {{color: 'lightgray'}} onClick = {() => {this.RemovedLearnedSoundClick(sound_name)}}>✔ {sound_name} ✔</button>
		}
		else{
			return <button type="button" class="list-group-item list-group-item-action" onClick = {() => {this.SpecificSoundClick(sound_name, 'adv')}}>{sound_name} </button>
		}}
	)


		learned_sounds_num = learned_sounds_list.length
		ls.set("stored_ls", JSON.stringify(learned_sounds_list))

		beginner_learned_sounds_num = 0
		inter_learned_sounds_num = 0
		adv_learned_sounds_num = 0 
		for(var sound of learned_sounds_list){
			if(beginner_list_sounds.includes(sound)){
				beginner_learned_sounds_num += 1
			}


			if(inter_list_sounds.includes(sound)){
				inter_learned_sounds_num += 1
			}

			if(adv_list_sounds.includes(sound)){
				adv_learned_sounds_num += 1
			}
		}
		console.log(adv_learned_sounds_num)
		console.log(learned_sounds_list)
		beginner_tab_text = "Beginner (" + beginner_learned_sounds_num + '/' + beginner_list_sounds.length + ')' 
		inter_tab_text = "Intermediate (" + inter_learned_sounds_num + '/' + inter_list_sounds.length + ')' 
		adv_tab_text = "Advanced (" + adv_learned_sounds_num + '/' + adv_list_sounds.length + ')' 
		this.forceUpdate();

		axios.get(current_url).then(res => {
	      	let same_data = res.data
	      	for (var lsound of learned_sounds_list){
	      		same_data =  same_data.filter(vid =>
	      		{
	      			return !(vid.sound === lsound & !last_four_videos_seen.includes(vid.url))
	      		})
      	}
	      	var keys = Object.keys(same_data);
			var len = keys.length

			if (learned_sounds_list.length === 5 && sounds_5_learned === false){
			toast.success("Off to a great start! You've learned 5 sounds!", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				})
			}

			if (learned_sounds_list.length === 10 && sounds_10_learned === false){
			toast.success("Keep building your sound arsenal! You've learned 10 sounds!", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				})
			}

			if (learned_sounds_list.length === 15 && sounds_15_learned === false){
			toast.success("Ready to drop some sick beats? You've learned 15 sounds!", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				})
			}

			if (learned_sounds_list.length === 20 && sounds_20_learned === false){
			toast.success("You could probably make some tutorials yourself! You've learned 20 sounds!", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				})
			}

			if (len < 1){
				// this.setState({
			 //    	learned_sounds: learned_sounds_list
	  	// 		}) //change url

			}
			else{
	      	let videoNum = Math.floor(Math.random() * len)
	      	let video = same_data[videoNum]
	      	title_text = video['title']
	      	channel_text = video['channel']
	      	sound_text = video['sound']
	      	this.setState({
		    	url: video['url'],
		    	title: video['title'],
		    	sameSoundText: sound_text,
		    	learned_sounds: learned_sounds_list,
		    	channel: video['channel']
	  		}) //change url
	      	

	}})
	}

  render(){
	  return (
	    <div className="Watch">
	    <Router>
	    <Route path = '/about' component = {About}/>
	    <Navbar bg="light" expand="md">
	    	<Navbar.Brand href="/">Beating The Boxes</Navbar.Brand>

			<Popup trigger={<Nav.Link> Help </Nav.Link>}     
			modal
    		closeOnDocumentClick>
			  <AwesomeSlider>
			    <div data-src = "https://github.com/zhuf11/beating-the-boxes/blob/master/tutorial_images/diff_buttons_slide.PNG?raw=true">
			    </div>
			    <div data-src="https://github.com/zhuf11/beating-the-boxes/blob/master/tutorial_images/difficulty_tabs_slide.PNG?raw=true">
			    </div>
			    <div data-src = "https://github.com/zhuf11/beating-the-boxes/blob/master/tutorial_images/other_buttons_slide.PNG?raw=true">
			    </div>
			    <div data-src="https://github.com/zhuf11/beating-the-boxes/blob/master/tutorial_images/learned_slide.PNG?raw=true">
			    </div>
			  </AwesomeSlider>
			</Popup>
	    	  <Navbar.Collapse className="justify-content-end">
			    <Navbar.Text className='sounds_learned_text'>
			      Sounds Learned: {learned_sounds_num}
			    </Navbar.Text>
			  </Navbar.Collapse>
	    </Navbar>		


	      <header className="App-header">
	        <ReactPlayer url = {this.state.url} controls = {true}/>
		    <ButtonGroup size = 'lg' aria-label="group">
				  <Button variant="outline-primary mb-2 mt-2" className = 'difficulty_buttons' onClick = {this.BasicButtonClick}>Boots & Cats (Basics) </Button>
			      <Button variant="outline-primary mb-2 mt-2" className = 'difficulty_buttons' onClick = {this.BeginnerButtonClick}>Beginner</Button>
			      <Button variant="outline-primary mb-2 mt-2" className = 'difficulty_buttons' onClick = {this.InterButtonClick}>Intermediate</Button>
			      <Button variant="outline-primary mb-2 mt-2" className = 'difficulty_buttons' onClick = {this.AdvButtonClick}>Advanced</Button>
			</ButtonGroup>

			<ButtonGroup size = 'med' aria-label="group">
				<Button variant="outline-warning px-4 mr-2 mb-3" onClick = {this.SaveVideoButtonClick}>{save_button_text}</Button>
				<Button variant="outline-primary mr-2 mb-3" onClick = {this.SameSoundButtonClick}>Watch Another {this.state.sameSoundText} Tutorial</Button>
				<Button variant="outline-success mb-3" onClick = {this.LearnedSoundButtonClick}>I've learned this sound!</Button>
			</ButtonGroup>

			<h2 style={{ color: 'black' }}> Now Watching: </h2>
	        <div style={{ color: 'black', fontSize: 26 }}>Title: {title_text}</div>
	        <div style={{ color: 'black', fontSize: 26 }}>Channel: {channel_text}</div>
	        <div style={{ color: 'black', fontSize: 26, paddingBottom:25 }}>Sound: {sound_text}</div>
	      </header>
	     </Router>
			<Tabs id = 'sound_tabs' defaultActiveKey="beginner" onSelect= {scroll_to_tabs}>

			  <Tab eventKey="beginner" title={beginner_tab_text}>
			    <ListGroup>
			    	{bl_sounds}
				</ListGroup>
			  </Tab>
			  <Tab eventKey="inter" title={inter_tab_text}>
			    {inter_sounds}
			  </Tab>
			  <Tab eventKey="adv" title={adv_tab_text}>
			    {adv_sounds}
			  </Tab>
			  <Tab eventKey="saved" title={saved_videos_text}>
			  	{saved_videos}   
			  </Tab>
			</Tabs>
	    </div>

	  );
	}
}

export default Watch;
